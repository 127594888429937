<template>
	<div class="team-preview">
		<div class="deals-wrapper card-shadow">
			<div class="team-fields pb-2">
				<div class="team-grp">
					<v-layout class="px-4 py-2 border-bottom">
						<div class="my-auto font-level-3-bold fw-600">Total Activity</div>
						<v-spacer></v-spacer>
						<div class="d-flex">
							<v-select
								class="cm-menu-select elevation-0 red--text"
								v-model="status"
								:items="StatusList"
								hide-details
								dense
								solo
								:menu-props="{ offsetY: true, offsetX: true }"
							>
								<template v-slot:selection="{ item }">
									<span class="blue--text font-level-3-bold">{{ item }}</span>
								</template>
							</v-select>
						</div>
					</v-layout>
					<v-row>
						<v-col md="8" class="v-data-table bt-table theme--light">
							<div class="v-data-table__wrapper">
								<table>
									<thead>
										<tr>
											<th width="300">Name</th>
											<th width="200">Total Activity</th>
											<th width="200">ON Page</th>
											<th width="200">OFF Page</th>
											<!-- <th width="150">Deals Status</th> -->
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<p class="fw-500 mb-0">Absternet LLC</p>
											</td>
											<td>
												<v-chip outlined color="cyan darken-3">
													<p class="fw-600 mb-0">100</p>
												</v-chip>
											</td>
											<td>
												<v-chip outlined color="red darken-3">
													<p class="fw-600 mb-0">60</p>
												</v-chip>
											</td>
											<td>
												<v-chip outlined color="green darken-3">
													<p class="fw-600 mb-0">40</p>
												</v-chip>
											</td>
										</tr>
										<tr>
											<td>
												<p class="fw-500">Absternet LLC</p>
											</td>
											<td>
												<v-chip outlined color="cyan darken-3">
													<p class="fw-600 mb-0">100</p>
												</v-chip>
											</td>
											<td>
												<v-chip outlined color="red darken-3">
													<p class="fw-600 mb-0">60</p>
												</v-chip>
											</td>
											<td>
												<v-chip outlined color="green darken-3">
													<p class="fw-600 mb-0">40</p>
												</v-chip>
											</td>
										</tr>
										<tr>
											<td>
												<p class="fw-500">Absternet LLC</p>
											</td>
											<td>
												<v-chip outlined color="cyan darken-3">
													<p class="fw-600 mb-0">100</p>
												</v-chip>
											</td>
											<td>
												<v-chip outlined color="red darken-3">
													<p class="fw-600 mb-0">60</p>
												</v-chip>
											</td>
											<td>
												<v-chip outlined color="green darken-3">
													<p class="fw-600 mb-0">40</p>
												</v-chip>
											</td>
										</tr>
										<tr>
											<td>
												<p class="fw-500">Absternet LLC</p>
											</td>
											<td>
												<v-chip outlined color="cyan darken-3">
													<p class="fw-600 mb-0">100</p>
												</v-chip>
											</td>
											<td>
												<v-chip outlined color="red darken-3">
													<p class="fw-600 mb-0">60</p>
												</v-chip>
											</td>
											<td>
												<v-chip outlined color="green darken-3">
													<p class="fw-600 mb-0">40</p>
												</v-chip>
											</td>
										</tr>
										<tr>
											<td>
												<p class="fw-500">Absternet LLC</p>
											</td>
											<td>
												<v-chip outlined color="cyan darken-3">
													<p class="fw-600 mb-0">100</p>
												</v-chip>
											</td>
											<td>
												<v-chip outlined color="red darken-3">
													<p class="fw-600 mb-0">60</p>
												</v-chip>
											</td>
											<td>
												<v-chip outlined color="green darken-3">
													<p class="fw-600 mb-0">40</p>
												</v-chip>
											</td>
										</tr>
										<tr>
											<td>
												<p class="fw-500">Absternet LLC</p>
											</td>
											<td>
												<v-chip outlined color="cyan darken-3">
													<p class="fw-600 mb-0">100</p>
												</v-chip>
											</td>
											<td>
												<v-chip outlined color="red darken-3">
													<p class="fw-600 mb-0">60</p>
												</v-chip>
											</td>
											<td>
												<v-chip outlined color="green darken-3">
													<p class="fw-600 mb-0">40</p>
												</v-chip>
											</td>
										</tr>
									</tbody>
									<tfoot class="border-light-grey border-left-0">
										<tr>
											<td class="border">
												<p class="fw-500"></p>
											</td>
											<td class="border">
												<span class="fw-600" style="font-size: 13px">600</span>
											</td>
											<td class="border">
												<span class="fw-600" style="font-size: 13px">360</span>
											</td>
											<td class="border">
												<span class="fw-600" style="font-size: 13px">240</span>
											</td>
										</tr>
									</tfoot>
								</table>
							</div>
						</v-col>
						<v-col md="4" style="display: grid; place-items: center">
							<apexchart type="pie" :options="chartOptions" :series="series"></apexchart>
						</v-col>
					</v-row>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
export default {
	name: "team-member",
	title: "Listing Team",
	mixins: [ListingMixin],
	data() {
		return {
			status: "All Time",
			StatusList: ["All Time", "This Week", "Last Week", "This Month", "Last Month"],
			series: [40, 60],
			chartOptions: {
				chart: {
					// width: "100%",
					type: "pie",
					height: "auto",
				},
				labels: ["OFF Page", "ON Page"],
				theme: {
					monochrome: {
						enabled: false,
					},
				},
				plotOptions: {
					pie: {
						dataLabels: {
							offset: -10,
						},
					},
				},
				// dataLabels: {
				// 	formatter(val, opts) {
				// 		const name = opts.w.globals.labels[opts.seriesIndex];
				// 		return [name, val.toFixed(1) + "%"];
				// 	},
				// },
				legend: {
					show: false,
				},
			},
		};
	},
};
</script>

<style scoped>
.v-progress-circular {
	margin: 1rem;
}
</style>
