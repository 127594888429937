<template>
	<div class="team-preview">
		<div class="view-wrapper">
			<v-container fluid>
				<v-row dense>
					<v-col cols="4">
						<v-card class="card_shadow p-5">
							<div class="team_member d-flex justify-content-between align-items-center">
								<div class="font-team h3 grey--text">TOTAL PROJECTS</div>
								<!-- <div class="icon_box h3 mb-0 success lighten-4">90</div> -->
								<div class="d-flex align-center">
									<!-- <div class="icon_box h3 mb-0 red lighten-4 mr-2">45</div> -->
									<v-progress-circular :size="50" :value="100" color="success">
										<!-- <span class="indigo--text fw-500">50%</span> -->
										<div class="icon_box h3 mt-2">90</div>
									</v-progress-circular>
								</div>
							</div>
						</v-card>
					</v-col>
					<v-col cols="4">
						<v-card class="card_shadow p-5">
							<div class="team_member d-flex justify-content-between align-items-center">
								<div class="font-team h3 grey--text">RED PROJECTS</div>
								<div class="d-flex align-center">
									<!-- <div class="icon_box h3 mb-0 red lighten-4 mr-2">45</div> -->
									<v-progress-circular :size="50" :value="(54 / 90) * 100" color="red darken-2">
										<!-- <span class="indigo--text fw-500">50%</span> -->
										<div class="icon_box h3 mt-2">54</div>
									</v-progress-circular>
								</div>
							</div>
						</v-card>
					</v-col>
					<v-col cols="4">
						<v-card class="card_shadow p-5">
							<div class="team_member d-flex justify-content-between align-items-center">
								<div class="font-team h3 grey--text">GREEN PROJECTS</div>
								<div class="d-flex align-center">
									<!-- <div class="icon_box h3 mb-0 green lighten-4 mr-2">45</div> -->
									<v-progress-circular :size="50" :value="(36 / 90) * 100" color="green darken-2">
										<div class="icon_box h3 mt-2">36</div>
										<!-- <span class="indigo--text fw-500">50%</span> -->
									</v-progress-circular>
								</div>
							</div>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script>
export default {
	name: "Viewdetails",
	data: () => ({
		cards: [
			{
				title: "Favorite road trips",
				src: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
				flex: 6,
			},
			{ title: "Best airlines", src: "https://cdn.vuetifyjs.com/images/cards/plane.jpg", flex: 6 },
		],
	}),
};
</script>
